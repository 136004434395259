import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import GatsbyImage from 'gatsby-image'

import Carousel from './carousel'
import LinkWrapper from './linkWrapper'
import { media } from '../utils/mediaQueries'

const Container = styled.div`
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
`

const Text = styled.p`
  width: 100%;
  margin-top: 40px;
`

const MediaWrapper = styled.div`
  ${ media.medium`
    /* width: 100%; */
    margin-left: -30px;
    margin-right: -30px;
  ` }
`

const Image = styled(GatsbyImage)`

  ${ ({ to }) => to && css`
    img {
      transition: opacity 0.5s ease 0s, transform 500ms !important;
    }

    &:hover {
      img {
        transform: scale(1.01);
        transition: opacity 0.5s ease 0s, transform 500ms !important;
      }
    }
  ` }
`

const Card = ({ title, media, text, to }) => {
  let cardMedia

  if (Array.isArray(media)) {
    if (media.length > 1) {
      cardMedia = <Carousel media={media}/>
    } else if (media.length === 1) {
      cardMedia = <Image to={to} fluid={media[0].fluid} alt={media[0].alt}/>
    } else {
      cardMedia = null
    }
  } else {
    cardMedia = <Image to={to} fluid={media.fluid} alt={media.alt}/>
  }

  return (
    <Container>
      {title &&
        <LinkWrapper to={to}>
          <h2>{title}</h2>
        </LinkWrapper>
      }
      <LinkWrapper to={to}>
        <MediaWrapper>
          {cardMedia}
        </MediaWrapper>
      </LinkWrapper>
      {text &&
        <Text>{text}</Text>
      }
    </Container>
  )
}

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.string,
  to: PropTypes.string,
}

export default Card
