import React from 'react'
import { graphql } from 'gatsby'
import PageWrapper from '../components/pageWrapper'
import Card from '../components/card'
import PageDescription from '../components/pageDescription'
import slugify from '../utils/slugify'
import HeroImage from '../components/heroImage'
import Footer from '../components/footer'

const DestinationsPage = ({ data: { datoCmsDestinationsPage: { name, description, heroImage, regions } } }) => {
  return (
    <>
      <HeroImage media={heroImage}/>
      <PageWrapper>
        <PageDescription
          title={name}
          text={description}
        />
        {regions.map(({ name, media, description, originalId }) => (
          <Card
            key={originalId}
            title={name}
            text={description}
            media={media}
            to={`/destinations/${ slugify(name) }`}
          />
        ))}
      </PageWrapper>
      <Footer/>
    </>
  )
}

export const query = graphql`
  query DestinationsPage {
    datoCmsDestinationsPage {
      name
      description
      heroImage {
        alt
        fluid(maxWidth: 1400, maxHeight: 630, imgixParams: {
          fm: "jpg",
          auto: "compress,format",
          fit: "crop",
          crop: "edges"
        }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      regions {
        name
        description
        originalId
        media {
          id
          alt
          fluid(maxWidth: 1000, maxHeight: 650, imgixParams: {
            fm: "jpg",
            auto: "compress,format",
            fit: "crop",
            crop: "faces"
          }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`

export default DestinationsPage
